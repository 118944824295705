/**
 * External Dependencies
 */

import 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

// import Flickity from 'flickity/dist/flickity.pkgd';
import * as basicScroll from 'basicscroll';
import Log from 'laravel-mix/src/Log';

var $ = require('jquery');
var jQueryBridget = require('jquery-bridget');
var Flickity = require('flickity');

// make Flickity a jQuery plugin
Flickity.setJQuery( $ );
jQueryBridget( 'flickity', Flickity, $ );

import Plyr from 'plyr';


$(document).ready(function() {

  // CUSTOM CURSOR
  // const cursor = document.querySelector('.cursor');
  // $(document).mousemove(function(d){
  //   cursor.style.top = d.clientY + 'px';
  //   cursor.style.left = d.clientX + 'px';
  // });

  // $('a, .button, .navbar .nav__menu li a, .footer a, .cursor-pointer').mouseover(
  //   function() {
  //     $('body').attr('data-cursor', 'link');
  //   }
  // ).mouseleave(
  //   function() {
  //     $('body').attr('data-cursor', 'normal');
  //   }
  // );

  // $('.slider, .egallery, .related-slider').mouseover(
  //   function() {
  //     $('body').attr('data-cursor', 'drag');
  //   }
  // ).mouseleave(
  //   function() {
  //     $('body').attr('data-cursor', 'normal');
  //   }
  // );

  // PLYR
  const players = Array.from(document.querySelectorAll('.plyr__video-embed'));

  players.map(player => new Plyr(player, {
    ratio: '16:9',
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
    autopause: true,
  }));

  // Modal

  $('#modal_toggle').on("click", function() {
    $('#modal_container').removeClass("opacity-0 invisible");
    $('#modal_container').addClass("opacity-100 modal_open");
    $('#modal_overlay').removeClass("opacity-0 invisible");
    $('#modal_overlay').addClass("opacity-70");
    $('body').addClass("overflow-y-hidden");
  });
  

  $('#close_modal').on("click", function() {
    $('#modal_container').removeClass("opacity-100");
    $('#modal_container').addClass("opacity-0 invisible");
    $('#modal_overlay').addClass("opacity-0 invisible");
    $('#modal_overlay').removeClass("opacity-70");
    $('body').removeClass("overflow-y-hidden");
  });

  // MENU IMAGE
  var menuId = $('.hamburger-nav li.current_page_item').prop('id');
  $(`.menu-image[data-id=${menuId}]`).addClass('active');

  $('.hamburger-nav li').hover(
    function () {
      var menuId = $(this).prop('id');
      $('.menu-image').removeClass('active');
      $(`.menu-image[data-id=${menuId}]`).addClass('active');
    },
    function () {
      var menuId = $('.hamburger-nav li.current_page_item').prop('id');
      $('.menu-image').removeClass('active');
      $(`.menu-image[data-id=${menuId}]`).addClass('active');
    }
  );

  $('.action-form').change(handleFilter);

  function handleFilter () {
    var uristring = '?category=';

    $('.action-filter option:checked').each(function(index) {
      var isLastElement = index == $('.action-filter option:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  $('.hamburger-nav li.current_page_item')

    // Swiper SLIDER
    var galleryThumbs = new Swiper(".productThumbSlider", {
      slidesPerView: 2.5,
      spaceBetween: 10,
      breakpoints: {
        540: {
          slidesPerView: 3.5,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 4.5,
          spaceBetween: 10,
        },
        1100: {
          slidesPerView: 5.5,
          spaceBetween: 10,
        },
      },
      freeMode: false,
      loop: false,
      threshold: 10,
      grabCursor: true,
      speed: 600,
      navigation: {
        prevEl: ".zz-swiper-button-prev",
        nextEl: ".zz-swiper-button-next",
      },
    });

    var galleryTop = new Swiper(".productImageSlider", {
      spaceBetween: 10,
      grabCursor: true,
      loop: true,
      threshold: 10,
      effect: 'creative',
      creativeEffect: {
        prev: {
          translate: ['-100%', 0, 0],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      },
      thumbs: {
        swiper: galleryThumbs,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

  // SLIDERS
  if($('.slider').length) {
    var $carousel = $('.slider').flickity({
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      cellAlign: 'center',
      freeScroll: true,
      draggable: true,
    });

    $(this).on( 'dragMove.flickity', function( event ) {
      cursor.style.top = event.originalEvent.y + 'px';
      cursor.style.left = event.originalEvent.x + 'px';
    });
  };

  // if($('.gallery').length) {
  //   var $carousel = $('.gallery').flickity({
  //     pageDots: false,
  //     prevNextButtons: true,
  //     wrapAround: true,
  //     cellAlign: 'center',
  //     draggable: true,
  //   });
  // };

  if($('.related-slider').length) {
    var $carousel = $('.related-slider').flickity({
      pageDots: false,
      prevNextButtons: false,
      freeScroll: false,
      cellAlign: 'left',
      draggable: true,
    });
  };

  // NAV SELECTOR
  if ($('.affix-menu').length) {
    var lastId,
        topMenu = $('.affix-menu li'),
        topMenuHeight = topMenu.outerHeight(),
        menuItems = topMenu.find('a'),
        scrollItems = menuItems.map(function(){
          var item = $($(this).attr('href'));
          if (item.length) { return item; }
        });

        menuItems.click(function(e){
          var href = $(this).attr('href'),
              offsetTop = href === '#' ? 0 : $(href).offset().top-topMenuHeight+1;
          $('html, body').stop().animate({
              scrollTop: offsetTop,
          }, 500);
          e.preventDefault();
        });

    // Bind to scroll
    $(window).scroll(function(){
       // Get container scroll position
       var fromTop = $(this).scrollTop()+topMenuHeight;

       // Get id of current scroll item
       var cur = scrollItems.map(function(){
         if ($(this).offset().top < fromTop)
           return this;
       });
       // Get the id of the current element
       cur= cur[cur.length-1];
       var id = cur && cur.length ? cur[0].id : '';

       if (lastId !== id) {
           lastId = id;
           // Set/remove active class
           menuItems
             .parent().removeClass('active')
             /* eslint-disable */
             .end().filter("[href='#"+id+"']").parent().addClass('active');
             /* eslint-enable */
       }
    });
  }

  // Add smooth scrolling to all links
  $(".scroll").on('click', function(event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 1000, function() {

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
      return false;
    } // End if
  });

  // SCROLLED NAV
  $(window).on('load scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  // CLOSE MESSAGE
  var message = localStorage.getItem('message');

  if(!message) {
    localStorage.setItem('message', true);
  }

  $('.close-message').on("click", function() {
    localStorage.setItem('message', false);
    $('body').toggleClass('hide--message');
  });

  $('body').delay(6000).queue(function(){
    localStorage.setItem('message', false);
    $(this).addClass("hide--message");
  });

  // TOGGLE VIDEO
  var vid = document.getElementById('video');

  $('.toggle--video').click(function () {
    if ($(vid).prop('muted')) {
      $(vid).prop('muted', false);
      $('body').addClass('video-active');

    } else {
      $(vid).prop('muted', true);
      $('body').removeClass('video-active');
    }
  });

  // TOGGLE FILTER
  $('.toggle--filter').on('click', function() {
    $('body').toggleClass('filter-active overflow-hidden');
    $('.close--filter').toggleClass('opacity-100');
  });

  $('.filter--backdrop, .close--filter').on('click', function() {
    $('body').removeClass('filter-active overflow-hidden');
    $('.close--filter').toggleClass('opacity-100');
  });

  $(document).keydown(function(event) {
    if (event.keyCode == 27) {
      $("body").removeClass("filter-active");
    }
  });

  if($('body').hasClass('filter-active')) {
    $('.filter--backdrop').toggleClass('invisible opacity-0')
    console.log($('.close--filter'));
    $('.close--filter').toggleClass('invisible opacity-0')
  }

  // HAMBURGER
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.close-nav').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  // NEWS FILTER
  $('.news-checkbox').change(handleNewsFilter);

  function handleNewsFilter () {

    var uristring = '?category=';

    $('.news-checkbox:checked').each(function(index) {
      var isLastElement = index == $('.news-checkbox:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  // DIVIDERS
  document.querySelectorAll('.divider').forEach((elem) => {

    basicScroll.create({
      elem: elem,
      from: 'top-top',
      to: 'bottom-bottom',
      direct: true,
      props: {
        '--tw-scale-y': {
          from: '0',
          to: `1`
        }
      }
    }).start()
  })

  $('.collapse-text__toggle').click(function() {
    $(this).toggleClass('active');
    $(this).parents('.collapse-text').find('.collapse-text__wrapper').toggleClass('acitve');
  });

  // TABNAV
  if($('.js-tabnav-tab').length) {
    (function($) {
      $.fn.tabnav = function tabnav(userConfig) {
        var config = $.extend({
          activeTabClass: 'active',
          activePanelClass: 'active',
        }, userConfig);

        var $wrapper = $(this);
        var $tabs = $wrapper.find('.js-tabnav-tab');
        var $panels = $wrapper.find('.js-tabnav-panel');
        var $panelsWrapper = $wrapper.find('.js-tabnav-panels');
        var $activeTab;
        var $activePanel;
        var activeTab = 0;

        $panels
        .attr('role', 'tabpanel')
        .attr('aria-hidden', 'true');

        $tabs
        .attr('role', 'tab')
        .attr('aria-selected', 'false')
        .attr('aria-setsize', $tabs.length)
        .attr('tabindex', 0)
        .each(function(i, e) {
          var $elem = $(e);
          if ($elem.hasClass(config.activeTabClass)) {
            setActiveTab(i);
          }
          $elem.attr('aria-posinset', i + 1);
        })
        .click(setTabs)
        .keypress(setTabs);

        function setActiveTab(tabPos) {
          $activeTab = $($tabs.get(tabPos))
          .addClass(config.activeTabClass)
          .attr('aria-selected', 'true');

          $activePanel = $($panels.get(tabPos))
          .addClass(config.activePanelClass)
          .attr('aria-hidden', 'false');

          $panelsWrapper.css('height', $activePanel.height() + 'px');
        }

        function setTabs(e) {
          if (e.type === 'keypress' && e.keyCode !== 13) {
            return;
          }

          var $elem = $(e.currentTarget);

          var tabPos = Number($elem.attr('aria-posinset')) - 1;
          $tabs
          .removeClass(config.activeTabClass)
          .attr('aria-selected', 'false');
          $panels
          .removeClass(config.activePanelClass)
          .attr('aria-hidden', 'true');

          setActiveTab(tabPos);
        }

        $(window).resize(function onWindowResize() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });

        $(window).load(function() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });
      }

      var tabnav = $('.tabnav');
      if (tabnav.length != 0) {
        $(tabnav).each(function(){
          $(this).tabnav();
        });
      }
    }(jQuery));
  }

});

gform.addFilter('gform_datepicker_options_pre_init', function (optionsObj, formId, fieldId) {
  if (formId == 2 && fieldId == 4) {
      optionsObj.firstDay = 1;
      optionsObj.beforeShowDay = function (date) {
              var day = date.getDay();

          return [!(day == 0)];
      };
  }

  return optionsObj;
});

gform.addFilter('gform_datepicker_options_pre_init', function (optionsObj, formId, fieldId) {
  if (formId == 3 && fieldId == 4) {
      optionsObj.firstDay = 1;
      optionsObj.beforeShowDay = function (date) {
              var day = date.getDay();

          return [!(day == 0)];
      };
  }

  return optionsObj;
});
